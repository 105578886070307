import { nanoid } from 'nanoid';

// Header Data
export const headData = {
  title: 'Mariusz Żurawski',
  lang: 'en',
  description: 'Mariusz Żurawski Home Page',
};

// Page Data
export const heroData = {
  title: 'Hello, my name is',
  name: 'Mariusz',
  subtitle: 'I am a Developer and this page is under construction...',
};

// Contact Data
export const contactData = {
  cta: 'Would you like to contact with me?',
  btn: 'send e-mail',
  email: 'mariusz@zurawski.dev',
};

// Footer Data
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'facebook',
      url: 'https://www.facebook.com/profile.php?id=1536394169',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/mariusz-zurawski/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/zuraw2006',
    },
  ],
};
